import {createApp} from 'vue'
import App from './App.vue'
import store from './store'
import router from "./router";
import VueGtag from "vue-gtag";
//import VueYandexMetrika from 'vue-v3-yandex-metrika'


import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App).use(router);
app.use(store);
app.use(router);
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyD7-un0s7EHnFMh-yNDKC5xMi6pZE2cYEw'
    },
});
app.use(VueGtag, {
    config: {
        id: "G-J5EG0D7PZ1"
    }
}, router);

/*
app.use(VueYandexMetrika, {
    id: 86051866,
    router: router,
    env: process.env.NODE_ENV
    // other options
})*/

app.mount('#app')
